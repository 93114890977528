import { useCallback, useEffect } from 'react';
import { useState } from 'react'
import { cryptosList } from './constant';
import { capitalize, debounce, find, lowerCase, uniq } from 'lodash';
import Dropdown from './Dropdown';
import Loadder from './Lodder';
import TransfiModal from './TransfiModal';

export default function Sell() {
    const [selectedCrypto, setSelectedCrypto] = useState('ETH');
    const [selectedFiat, setSelectedFiat] = useState('PHP');
    const [cryptoDetails, setCryptoDetails] = useState({});
    const [cryptoValue, setCryptoValue] = useState(0);
    const [fiatValue, setFiatValue] = useState(0);
    const [error, setError] = useState(false);
    const [exchangeRateData, setExchangeRateData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(false);
    const [fiatList, setFiatList] = useState([]);
    const [cryptoList, setCryptoList] = useState([]);
    const [allFiatList, setAllFiatList] = useState([]);
    const [allCryptoList, setAllCryptoList] = useState([]);

    useEffect(() => {
        fetchCryptoConfigurations();
        fetchFiatConfigurations();
    }, []);

    useEffect(() => {
        fetchCryptoPrice();
    }, [selectedFiat, selectedCrypto]);

    const fetchCryptoConfigurations = () => {
        fetch(`https://dev-microservices.transfi.com/api/exchanges/common/configuration?name=crypto&module=sellCrypto`)
            .then(response => response.json())
            .then(resp => {
                const { data } = resp || {};
                setAllCryptoList(data?.docs);
                const cryptos = [];
                data?.docs?.map(cr => {
                    cryptos.push({
                        value: cr?.formattedSymbol,
                        label: `${cr?.symbol}${cr?.showNetwork ? " " + cr?.network : ""}`
                    })
                })
                setCryptoList(cryptos);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const fetchFiatConfigurations = () => {
        fetch(`https://dev-microservices.transfi.com/api/exchanges/common/configuration?name=fiat&module=sellCrypto`)
            .then(response => response.json())
            .then(resp => {
                const { data } = resp || {};
                let fiats = [];
                setAllFiatList(data?.docs);
                data?.docs?.map(cr => {
                    fiats.push(cr?.description)
                })
                let fiatNewList = [];
                uniq(fiats).sort().map(cr => {
                    fiatNewList.push({ label: cr, value: cr })
                })
                setFiatList(fiatNewList);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const fetchCryptoPrice = () => {
        fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${cryptosList[selectedCrypto]}&vs_currencies=${selectedFiat}`)
            .then(response => response.json())
            .then(data => {
                setCryptoDetails(data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const onClickBuy = () => {
        setLoading(true);
        if (fiatValue <= 0 || cryptoValue <= 0) {
            setError("Fill some amount to buy.")
        } else {
            // openModal(true);
            createOrder();
        }
        setLoading(false);
    }


    const createOrder = () => {
        let selectedCountry = find(allFiatList, { description: selectedFiat });
        selectedCountry = selectedCountry?.symbol.split(" ").join("+");
        let selectedNetwork = find(allCryptoList, { symbol: selectedCrypto });
        selectedNetwork = selectedNetwork?.network;
        const url = `https://sandbox-buy.transfi.com/?apiKey=m1M2NifvL3NxQcsU&view=sell&fiatTicker=${selectedFiat}&country=${selectedCountry}&cryptoTicker=${selectedCrypto}&cryptoNetwork=${selectedNetwork}&cryptoAmount=${cryptoValue}`;
        console.log(url);
        setPaymentUrl(url);
        setIsOpen(true);
    }

    const onChangeCrypto = (e) => {
        if (error) {
            setError(false)
        }
        setCryptoValue(e.target.value);
        debouncedFetchData(e.target.value);
    }

    const fetchExchangeRate = (amount) => {
        setLoading(true);

        fetch(`https://sandbox-microservices.transfi.com/api/exchanges/common/exchangeRates?cryptoTicker=${selectedCrypto}&fiatTicker=${selectedFiat}&amount=${amount}&product=sell&paymentType=local&quoteVersion=v1&apiKey=XsLXL8PsQDFpBllS`)
            .then(response => response.json())
            .then(resp => {
                setExchangeRateData(resp?.data);
                const { receiveAmount } = resp?.data || {};
                if (receiveAmount > 0) {
                    setFiatValue(receiveAmount);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error?.error?.message)
                setLoading(false);
            });
    }

    const debouncedFetchData = useCallback(
        debounce((amount) => fetchExchangeRate(amount), 500),
        []
    );

    return (
        <>
            <>
                <div>
                    <div className='text-center border-gray-300 p-1 border-[1px] mx-12 mt-8 rounded-md'>
                        <div class="flex">
                            <div class="relative w-full">
                                <input value={cryptoValue} onChange={(e) => onChangeCrypto(e)} type="number" class="block p-2.5 w-full z-20 text-sm text-gray-90 border-0 outline-none focus:ring-0 focus:ring-offset-0" placeholder="0.00" required />
                            </div>
                            <Dropdown
                                value={selectedCrypto}
                                valueList={cryptoList}
                                setSelected={setSelectedCrypto}
                            />
                        </div>
                    </div>
                    <div className='text-center border-gray-300 p-1 border-[1px] mx-12 mt-2 rounded-md'>
                        <div class="flex">
                            <div class="relative w-full">
                                <input value={fiatValue} type="number" class="block p-2.5 w-full z-20 text-sm text-gray-90 border-0 outline-none focus:ring-0 focus:ring-offset-0" placeholder="0.00" required />
                            </div>
                            <Dropdown
                                value={selectedFiat}
                                valueList={fiatList}
                                setSelected={setSelectedFiat}
                            />
                        </div>
                    </div>
                </div>
                {error ? <div className='text-center my-3 mx-12'><p className='text-red-500'>{error}</p></div> : <></>}
                <div className='text-center my-3 mx-12'>
                    {loading ? <div style={{ marginLeft: '48%' }}><Loadder /></div> : <button onClick={() => { onClickBuy() }} type="button" class="w-full text-white bg-[#1a4fd6] hover:bg-[#1a4fd6ad] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none cursor-pointer">{`Sell ${selectedCrypto}`}</button>}
                </div>
            </>
            <TransfiModal isOpen={isOpen} setIsOpen={setIsOpen} iframeUrl={paymentUrl} />
        </>
    )
}
