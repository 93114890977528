import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import logo from './transfi-logo.svg'

import WithoutKYC from './components/withoutkyc/WithoutKYC';
import HomePage from './components/HomePage';
import Buy from './components/buy/Buy';
import Sell from './components/sell/Sell';
import BuyStandAlone from './components/buy/standalone/BuyStandAlone';
import SellStandAlone from './components/buy/standalone/SellStandAlone';
import WithKYC from './components/withKyc/WithKYC';
import Collections from './components/withoutkyc/Collections';
import ProgressComponent from './components/ProgressComponent';

function App() {
  useEffect(() => {
    fetchUsdConversionRate();
  }, []);

  const fetchUsdConversionRate = () => {
    fetch(`https://microservices.transfi.com/api/exchanges/common/limits?product=buy`)
      .then(response => response.json())
      .then(resp => {
        sessionStorage.setItem("buyLimitWithourKyc", JSON.stringify(resp?.data));
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div className='bg-gray-100 h-screen'>
      <BrowserRouter>
        <div class="header">
          <a href="/" class="router-link-exact-active router-link-active text-l text-white flex">
            {/* <img src={logo}
              alt="Bitpace Logo" class="header-logo" /> */}
            <p class="text-3xl font-semi-bold pt-2 ml-2" >ABC Exchange</p>
          </a>
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/demo-without-kyc" element={<WithoutKYC />} />
          <Route path="/demo-collections" element={<Collections />} />
          <Route path="/demo-with-kyc" element={<WithKYC />} />
          <Route path="/buy/stand-alone" element={<BuyStandAlone />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/sell/stand-alone" element={<SellStandAlone />} />
          <Route path="/order-summary" element={<ProgressComponent />} />
        </Routes>
      </BrowserRouter>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          // Define default options
          className: '',
          style: {
            minWidth: "400px",
            height: "50px",
            fontSize: "20px",
            paddingLeft: "30px"
          },
          success: {
            duration: 3000
          },
          error: {
            duration: 5000
          },
        }}
      />
    </div >
  );
}

export default App;
