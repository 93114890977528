import { useCallback, useEffect, Fragment } from 'react';
import { useState } from 'react'
import { cryptosList } from './constant';
import { capitalize, debounce, find, lowerCase, uniq, isEmpty } from 'lodash';
import Dropdown from './Dropdown';
import Loadder from './Lodder';
import TransfiModal from './TransfiModal';
import moment from 'moment';

export default function Buy() {
    const [selectedCrypto, setSelectedCrypto] = useState('USDC');
    const [selectedFiat, setSelectedFiat] = useState('PHP');
    const [cryptoDetails, setCryptoDetails] = useState({});
    const [cryptoValue, setCryptoValue] = useState(0);
    const [fiatValue, setFiatValue] = useState(0);
    const [error, setError] = useState(false);
    const [exchangeRateData, setExchangeRateData] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(false);
    const [fiatList, setFiatList] = useState([]);
    const [cryptoList, setCryptoList] = useState([]);
    const [allFiatList, setAllFiatList] = useState([]);
    const [priceLimit, setPriceLimit] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState("local");
    const [selectedPaymentCode, setSelectedPaymentCode] = useState({});

    useEffect(() => {
        fetchCryptoConfigurations();
        fetchFiatConfigurations();
        setPriceLimit(JSON.parse(sessionStorage.getItem("buyLimitWithourKyc")));
    }, []);

    useEffect(() => {
        fetchCryptoPrice();
        if (fiatValue > 0){
            fetchExchangeRate(fiatValue, selectedFiat, selectedCrypto);
        } else {
            setCryptoValue(0);
        }
    }, [selectedFiat, selectedCrypto]);

    const fetchCryptoConfigurations = () => {
        setLoading(true);
        const cryptos = [
            {
                value: 'ETH',
                label: 'ETH ERC20'
            },
            {
                value: 'USDC',
                label: 'USDC ERC20'
            }
        ]

        setCryptoList(cryptos);
        setLoading(false);
    }

    const fetchFiatConfigurations = () => {
        setLoading(true);
        fetch(`https://sandbox-microservices.transfi.com/api/exchanges/common/configuration?name=fiat&module=buyCrypto`)
            .then(response => response.json())
            .then(resp => {
                const { data } = resp || {};
                setAllFiatList(data?.docs);
                let fiats = [];
                data?.docs?.map(cr => {
                    fiats.push(cr?.description)
                })
                let fiatNewList = [];
                uniq(fiats).sort().map(cr => {
                    fiatNewList.push({ label: cr, value: cr })
                })
                setFiatList(fiatNewList);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const fetchCryptoPrice = () => {
        setLoading(true);
        fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${cryptosList[selectedCrypto]}&vs_currencies=${selectedFiat}`)
            .then(response => response.json())
            .then(data => {
                setCryptoDetails(data);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const onClickBuy = () => {
        if (fiatValue <= 0 || cryptoValue <= 0) {
            setError("Fill some amount to buy.")
        } else {
            createOrder();
        }
    }

    const walletAddress = {
        'ETH': '0x3Eda4034519644527CdA0760b00e5a983843F670',
        'USDC': '0x3Eda4034519644527CdA0760b00e5a983843F670'
    }

    const createOrder = () => {
        setLoading(true);
        let selectedCountry = find(allFiatList, {description: selectedFiat});
        selectedCountry = selectedCountry?.symbol.split(" ").join("+");
        const url = `https://sandbox-buy.transfi.com/?fiatTicker=${selectedFiat}&country=${selectedCountry}&cryptoTicker=${selectedCrypto}&cryptoNetwork=ERC20&walletAddress=${walletAddress[selectedCrypto]}&fiatAmount=${fiatValue}`;
        console.log(url);
        setPaymentUrl(url);
        setIsOpen(true);
        setLoading(false);
    }

    const onChangeFiat = (e) => {
        if (error) {
            setError(false)
        }
        setFiatValue(e.target.value);
        debouncedFetchData(e.target.value, selectedFiat, selectedCrypto);
    }

    const fetchExchangeRate = (amount, fiat, cryptoTicker) => {
        setError(false);
        setLoading(true);
        let fiatCurrency = fiat || selectedFiat;
        const limitsMin = priceLimit['min'] || JSON.parse(sessionStorage.getItem("buyLimitWithourKyc"))['min'];
        const limitsMax = priceLimit['max'] || JSON.parse(sessionStorage.getItem("buyLimitWithourKyc"))['max'];
        if (amount < (limitsMin[fiatCurrency])) {
            setError(`Min Amount Should be ${(limitsMin[fiatCurrency])} ${fiatCurrency}`);
            setLoading(false);
            return;
        } else if (amount > limitsMax[fiatCurrency]) {
            setError(`Max Amount Should be ${limitsMax[fiatCurrency]} ${fiatCurrency}`);
            setLoading(false);
            return;
        }
        let quoteVersion = ['SGD', 'VND', 'MYR', 'THB', "JPY", "PHP", "IDR"].includes(fiatCurrency) ? "v2" : "v1";

        let paymentType = quoteVersion === "v2" ? "local" : "card";
        if (!isEmpty(selectedPaymentCode) && selectedPaymentCode?.value.includes("card")) {
            paymentType = "card";
        } else if (!isEmpty(selectedPaymentCode) && selectedPaymentCode?.value.includes("sepa_bank") && selectedPaymentCode?.value.includes("sepa_bank_va")) {
            paymentType = "sepa_bank";
        } else if(!isEmpty(selectedPaymentCode) && selectedPaymentCode?.value.includes("bank")){
            paymentType = "bank";
        }

        setSelectedPaymentType(paymentType);

        fetch(`https://sandbox-microservices.transfi.com/api/exchanges/common/exchangeRates?cryptoTicker=${cryptoTicker}&fiatTicker=${fiatCurrency}&amount=${amount}&product=buy&paymentType=${paymentType}&quoteVersion=${quoteVersion}&apiKey=XsLXL8PsQDFpBllS`)
            .then(response => response.json())
            .then(resp => {
                setExchangeRateData(resp?.data);
                const { receiveAmount } = resp?.data || {};
                if (receiveAmount > 0) {
                    setCryptoValue(receiveAmount);
                }
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setError(error?.error?.message)
                setLoading(false);
            });
    }

    const debouncedFetchData = useCallback(
        debounce((amount, selectedFiat, cryptoTicker) => fetchExchangeRate(amount, selectedFiat, cryptoTicker), 500),
        []
    );

    return (
        <>
            <>
                <div>
                    <div className='text-center border-gray-300 p-1 border-[1px] mx-12 mt-8 rounded-md'>
                        {/* <input value="1" /> */}
                        <div class="flex">
                            <div class="relative w-full">
                                <input value={fiatValue} onChange={(e) => onChangeFiat(e)} type="number" class="block p-2.5 w-full z-20 text-sm text-gray-90 border-0 outline-none focus:ring-0 focus:ring-offset-0" placeholder="0.00" required />
                            </div>
                            <Dropdown
                                value={selectedFiat}
                                valueList={fiatList}
                                setSelected={setSelectedFiat}
                            />
                        </div>
                    </div>
                    <div className='text-center border-gray-300 p-1 border-[1px] mx-12 mt-2 rounded-md'>
                        {/* <input value="1" /> */}
                        <div class="flex">
                            <div class="relative w-full">
                                <input value={cryptoValue} type="number" class="block p-2.5 w-full z-20 text-sm text-gray-90 border-0 outline-none focus:ring-0 focus:ring-offset-0" placeholder="0.00" required />
                            </div>
                            <Dropdown
                                value={selectedCrypto}
                                valueList={cryptoList}
                                setSelected={setSelectedCrypto}
                            />
                        </div>
                    </div>
                </div>
                {error ? <div className='text-center my-3 mx-12'><p className='text-red-500'>{error}</p></div> : <></>}
                <div className='text-center my-3 mx-12'>
                    {loading ? <div style={{ marginLeft: '48%' }}><Loadder /></div> : <button onClick={() => { onClickBuy() }} type="button" class="w-full text-white bg-[#1a4fd6] hover:bg-[#1a4fd6ad] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none cursor-pointer">{`Buy ${selectedCrypto}`}</button>}
                </div>
            </>
            <TransfiModal isOpen={isOpen} setIsOpen={setIsOpen} iframeUrl={paymentUrl} />
        </>
    )
}
