import { Table } from "flowbite-react";

const GainersTable = ({ tableCol, tableData }) => {
    console.log(tableData)
    return <Table>
        <Table.Head>
            {tableCol.map(col => {
                return <Table.HeadCell>
                    {col?.header}
                </Table.HeadCell>
            })}
        </Table.Head>
        <Table.Body className="divide-y">
            {
                tableData?.map(data => {
                    return <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        {tableCol.map(col => {
                            return <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {col?.formatter ? col?.formatter(data[col?.field], data) : data[col?.field]}
                            </Table.Cell>
                        })}
                    </Table.Row>
                })
            }
        </Table.Body>
    </Table>
};

export default GainersTable;