import logo from '../transfi-logo.svg'

import { useNavigate } from "react-router-dom";

const HomePage = () => {
    const navigate = useNavigate();

    return <>
    <div class="welcome">
        <div class="welcome2">
            <h3 class="welcome3">WELCOME TO PRODUCT DEMO</h3>
        </div>
    </div>
        <div class="flex w-screen items-center justify-center mt-16">
            <div class="w-[50%] pr-8 cursor-pointer" onClick={() => navigate("/buy")}>
                <div class="text-center max-w-md rounded overflow-hidden ml-auto drop-shadow-2xl  h-[300px] bg-white border-b-4 border-white-500 hover:border-blue-600 transition duration-500 ease-in-out">
                    <img class="w-1/4 mx-auto mt-12" src={logo} alt="Image" />
                    <div class="px-6 py-4">
                        <div class="font-thin text-5xl mb-2 text-center text-[#0F2288]">Buy</div>
                    </div>
                </div>
            </div>
           <div class="w-[50%] pl-8 cursor-pointer" onClick={() => navigate("/sell")}>
                <div class="max-w-md rounded overflow-hidden mr-auto drop-shadow-2xl h-[300px] bg-white border-b-4 border-white-500 hover:border-blue-600 transition duration-500 ease-in-out">
                    <div class="text-center max-w-md rounded overflow-hidden ml-auto drop-shadow-2xl  h-[300px] bg-white border-b-4 border-white-500 hover:border-blue-600 transition duration-500 ease-in-out">
                    <img class="w-1/4 mx-auto mt-12" src={logo} alt="Image" />
                    <div class="px-6 py-4">
                        <div class="font-thin text-5xl mb-2 text-center text-[#0F2288]">Sell</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
};

export default HomePage;