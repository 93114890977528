import { useState } from 'react';
import Buy from './Buy';
import Sell from './Sell';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import GainersTable from '../GainersTable';
import { gainers, newCoinsList } from './constant';

export default function WithoutKYC(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    let activeClass = "cursor-pointer text-xl text-[#1a4fd6]";
    let nonActiveClass = "cursor-pointer text-xl";

    const tableCol = [{
        header: "#",
        field: "market_cap_rank"
    },
    {
        header: "Coin",
        field: "name",
        formatter: (cell, row) => {
            return <div className='flex'><img className="w-8 h-8" src={row?.image} /><p className='pt-[6px] ml-2'>{`${row?.name} ${row?.symbol.toUpperCase()}`}</p></div>
        }
    },
    {
        header: "Price",
        field: "usd",
        formatter: (cell, row) => {
            return `$ ${Number(cell).toFixed(4)}`
        }
    },
    {
        header: "Volume",
        field: "usd_24h_vol",
        formatter: (cell, row) => {
            return `$ ${Number(cell).toFixed(4)}`
        }
    },
    {
        header: "1h Change",
        field: "usd_1h_change",
        formatter: (cell, row) => {
            return <p className='text-green-500'>{`${Number(cell).toFixed(2)}%`}</p>
        }
    }];

    const tableColl = [{
        header: "#",
        field: "market_cap_rank"
    },
    {
        header: "Coin",
        field: "name",
        formatter: (cell, row) => {
            return <div className='flex'><img className="w-8 h-8" src={row?.image} /><p className='pt-[6px] ml-2'>{`${row?.name} ${row?.symbol.toUpperCase()}`}</p></div>
        }
    },
    {
        header: "Price",
        field: "usd",
        formatter: (cell, row) => {
            return `$ ${Number(cell).toFixed(4)}`
        }
    },
    {
        header: "Volume",
        field: "usd_24h_vol",
        formatter: (cell, row) => {
            return `$ ${Number(cell).toFixed(4)}`
        }
    },
    {
        header: "1h Change",
        field: "usd_1h_change",
        formatter: (cell, row) => {
            return <p className='text-red-500'>{`${Number(cell).toFixed(2)}%`}</p>
        }
    }];

    const [productType, setProductType] = useState(searchParams.get('type'));
    return (
        <>
            <div className="bg-gray-100 py-8">
                <div className="mx-auto px-6">
                    <div className="flex">
                        <div className="px-8 w-[65%] block">
                            {/* <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#1a4fd6]">Exchange Demo - {capitalize(productType)}</h2>
                                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900">Without KYC</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    {`The revolutionary platform that allows you to ${productType} cryptocurrencies without KYC requirements. Experience the freedom of trading anonymously with Transfi. Try our demo today!`}
                                </p> */}
                            <p className='text-3xl font-semibold'>🚀 Top Gainers</p>
                            <div className='pt-8'>
                                <GainersTable tableCol={tableCol} tableData={gainers?.top_gainers} />
                            </div>
                            <p className='text-3xl font-semibold pt-8'>🚀 Top Losers</p>
                            <div className='pt-8'>
                                <GainersTable tableCol={tableColl} tableData={gainers?.top_losers} />
                            </div>
                        </div>
                        <div className='block w-[500px]'>
                            <div className='pt-16 relative'>
                                <div className='float-right text-right bg-white rounded-xl shadow-md w-[450px]'>
                                    <div className='flex mt-4 h-8'>
                                        <div className='w-1/2 border-r-[1px] border-gray-400 text-center'>
                                            <a className={`${productType === "buy" ? activeClass : nonActiveClass}`} href='/demo-without-kyc?type=buy'>Buy</a>
                                        </div>
                                        <div className='w-1/2 text-center'>
                                            <a className={`${productType === "sell" ? activeClass : nonActiveClass}`} href='/demo-without-kyc?type=sell'>Sell</a>
                                        </div>
                                    </div>
                                    {productType === "buy" ? <Buy /> : <Sell />}
                                </div>
                            </div>
                            <div className='float-right bg-white rounded-xl shadow-md w-[450px] mt-8 p-4'>
                                <p className='text-xl font-semibold mb-6'>New Cryptocurrencies</p>
                                {newCoinsList.map(coin => {
                                    return <div className='flex p-2 border-t-gray-300 border-b-gray-300 border-solid'>
                                        <img src={coin?.image}></img>
                                        <div className='text-sm pt-[5px] text-gray-500 pl-4'>{coin.name}<p>{coin?.time}</p></div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
