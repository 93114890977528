const cryptosList = {
    "BTC": 'bitcoin',
    "ETH": "ethereum",
    "LTC": "litecoin",
    "ADA": "cardano",
    "SOL": "solana",
    "MATIC": "matic-network",
    "USDC": "usd-coin",
    "USDT": "tether",
    "USDTTRC20": "tether",
    "USDTERC20": "tether",
    "AAVE": "aave",
    "ALGO": "algorand",
    "APT": "aptos",
    "ATOM": "cosmos",
    "ANKR": "ankr",
    "AVAX": "avalanche-2",
    "BCH": "bitcoin-cash",
    "BNB": "binancecoin",
    "BSV": "bitcoin-cash-sv",
    "BUSD": "binance-usd",
    "CELO": "celo",
    "DOGE": "dogecoin",
    "DOT": "polkadot",
    "FLOW": "flow",
    "FTM": "Fantom",
    "FUSD": "fuse-dollar",
    "FUSE": "fuse-network-token",
    "GLMR": "moonbeam",
    "HBAR": "hedera-hashgraph",
    "IMX": "immutable-x",
    "LINK": "chainlink",
    "MANA": "decentraland",
    "NEAR": "near",
    "XNO": "nano",
    "SHIB": "shiba-inu",
    "UNI": "unicorn-token",
    "UFARM": "unifarm",
    "XRP": "ripple",
    "XLM": "stellar",
    "ZIL": "zilliqa"
}

const fiatList = ['VND', 'PHP', 'THB', 'IDR', 'MYR', 'SGD', 'JPY', 'USD', 'AUD', 'EUR', 'DKK', 'NZD', 'PLN', 'CHF', 'AED', 'GBP']
const cryptoList = ['BTC', 'ETH', 'BNB', 'LTC', 'ADA', 'SOL', 'MATIC', 'USDC', 'USDT', 'AAVE', 'ALGO', 'ANKR', 'APT', 'ATOM', 'AVAX', 'BCH', 'BSV', 'BUSD', 'CELO', 'DOGE', 'DOT', 'FLOW', 'FTM', 'FUSE', 'GLMR', 'HBAR', 'IMX', 'LINK', 'MANA', 'NEAR', 'XNO', 'SHIB', 'UNI', 'UFARM', 'XLM', 'XRP', 'ZIL'];

const gainers = {
    "top_gainers": [
        {
            "id": "polkafoundry",
            "symbol": "pkf",
            "name": "Red Kite",
            "image": "https://assets.coingecko.com/coins/images/14422/original/red_kite.png?1679564335",
            "market_cap_rank": 799,
            "usd": 0.1619192870397595,
            "usd_24h_vol": 358994.19829009386,
            "usd_1h_change": 12.797617900208493
        },
        {
            "id": "wojak",
            "symbol": "wojak",
            "name": "Wojak",
            "image": "https://assets.coingecko.com/coins/images/29856/original/wojak.png?1681821640",
            "market_cap_rank": 1003,
            "usd": 0.00014824896995593702,
            "usd_24h_vol": 23423161.54769694,
            "usd_1h_change": 11.915396277329847
        },
        {
            "id": "saitarealty",
            "symbol": "srlty",
            "name": "SaitaRealty",
            "image": "https://assets.coingecko.com/coins/images/27289/original/s_mksCdB_400x400.jpeg?1663145048",
            "market_cap_rank": 899,
            "usd": 0.0004568688385236383,
            "usd_24h_vol": 307105.05832788337,
            "usd_1h_change": 11.070738382456648
        },
        {
            "id": "decimal",
            "symbol": "del",
            "name": "Decimal",
            "image": "https://assets.coingecko.com/coins/images/17133/original/logo_-_2021-07-16T133613.232.png?1626413833",
            "market_cap_rank": 251,
            "usd": 0.03573549870696678,
            "usd_24h_vol": 116591.91551063652,
            "usd_1h_change": 7.0948449093585495
        },
        {
            "id": "sperax",
            "symbol": "spa",
            "name": "Sperax",
            "image": "https://assets.coingecko.com/coins/images/12232/original/sperax_logo.jpg?1598342904",
            "market_cap_rank": 951,
            "usd": 0.007994134157502336,
            "usd_24h_vol": 5679942.561108898,
            "usd_1h_change": 6.933403587466319
        }
    ],
    "top_losers": [
        {
            "id": "galatasaray-fan-token",
            "symbol": "gal",
            "name": "Galatasaray Fan Token",
            "image": "https://assets.coingecko.com/coins/images/11585/original/Galatasaray-10.png?1604736731",
            "market_cap_rank": 718,
            "usd": 4.404521662896739,
            "usd_24h_vol": 1517043.0054998177,
            "usd_1h_change": -6.8043932629265305
        },
        {
            "id": "mute",
            "symbol": "mute",
            "name": "Mute",
            "image": "https://assets.coingecko.com/coins/images/14331/original/MUTE.png?1617618967",
            "market_cap_rank": 528,
            "usd": 0.9796087160958601,
            "usd_24h_vol": 558051.5644626311,
            "usd_1h_change": -4.984583365552409
        },
        {
            "id": "imgnai",
            "symbol": "imgnai",
            "name": "Image Generation AI",
            "image": "https://assets.coingecko.com/coins/images/28666/original/200x200-Nai.png?1672990319",
            "market_cap_rank": 972,
            "usd": 0.014120803141243244,
            "usd_24h_vol": 259760.58362293514,
            "usd_1h_change": -4.855153968026302
        },
        {
            "id": "euro-coin",
            "symbol": "euroc",
            "name": "Euro Coin",
            "image": "https://assets.coingecko.com/coins/images/26045/original/euro-coin.png?1655394420",
            "market_cap_rank": 440,
            "usd": 1.0963057182267386,
            "usd_24h_vol": 3515550.462985756,
            "usd_1h_change": -4.416697478135438
        },
        {
            "id": "metars-genesis",
            "symbol": "mrs",
            "name": "Metars Genesis",
            "image": "https://assets.coingecko.com/coins/images/26625/original/PRmUU8O1_400x400.jpeg?1659322669",
            "market_cap_rank": 301,
            "usd": 1.5518303017167563,
            "usd_24h_vol": 133154.6836545025,
            "usd_1h_change": -4.261467478702788
        }
    ]
}

const newCoinsList = [{
    name: 'Bitcoin',
    image: 'https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579',
    time: 'Added 2 days ago'
},{
    name: 'Ethereum',
    image: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
    time: 'Added 3 days ago'
},{
    name: 'Litecoin',
    image: 'https://assets.coingecko.com/coins/images/2/small/litecoin.png?1547033580',
    time: 'Added 4 days ago'
},{
    name: 'USDC',
    image: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    time: 'Added 5 days ago'
},{
    name: 'USDT',
    image: 'https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663',
    time: 'Added 6 days ago'
},{
    name: 'ANKR',
    image: 'https://assets.coingecko.com/coins/images/4324/small/U85xTl2.png?1608111978',
    time: 'Added 7 days ago'
}]

module.exports = {
    cryptosList,
    cryptoList,
    fiatList,
    gainers,
    newCoinsList
}