export default function SellStandAlone() {
    return (
        <>
            <div className="overflow-hidden bg-gray-100 py-16 h-screen">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 pt-14 w=[50%]">
                            <div className="lg:max-w-lg mt-32">
                                <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#1a4fd6]">Stand Alone Demo</h2>
                                <p className="mt-2 text-xl font-bold tracking-tight text-gray-900">Sell</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    With TransFi, Sell Your Crypto Effortlessly and Receive Money Directly in Your Bank Account - A Seamless Crypto Selling Experience!
                                </p>
                            </div>
                        </div>
                        <div className="w=[50%] pl-[180px]">
                            <div className="inline-block w-full p-6 my-8 overflow-hidden bg-white drop-shadow-xl rounded-2xl text-right">
                                <iframe id="transfiIframe" className="w-full" src="https://sandbox-buy.transfi.com/?view=sell&apiKey=m1M2NifvL3NxQcsU" title="W3Schools Free Online Web Tutorials" height={600}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
