import { useCallback, useEffect, Fragment } from 'react';
import Select from "react-select"
import { useState } from 'react'
import { cryptosList } from './constant';
import { debounce, isEmpty, round, unionBy, uniq, isArray } from 'lodash';
import Dropdown from './Dropdown';
import Loadder from './Lodder';
import TransfiModal from './TransfiModal';
import moment from 'moment';
import { Checkbox, Label } from 'flowbite-react';
import TransfiModalOrder from './TransfiModalOrder';

export default function Buy() {
    const [selectedCrypto, setSelectedCrypto] = useState({ value: 'USDT', label: 'USDT ERC20' });
    const [selectedFiat, setSelectedFiat] = useState('PHP');
    const [selectedPaymentCode, setSelectedPaymentCode] = useState({});
    const [cryptoDetails, setCryptoDetails] = useState({});
    const [cryptoValue, setCryptoValue] = useState(0);
    const [fiatValue, setFiatValue] = useState(20000);
    const [error, setError] = useState(false);
    const [exchangeRateData, setExchangeRateData] = useState({});
    const [quotes, setQuotes] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingExchangeRates, setLoadingExchangeRates] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState(false);
    const [fiatList, setFiatList] = useState([]);
    const [cryptoList, setCryptoList] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [priceLimit, setPriceLimit] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState("local");
    const [selectedCustomer, setSelectedCustomer] = useState("Openpayd");
    const [orderDetails, setOrderDetails] = useState({});
    const [cryptoCurrency, setCryptoCurrency] = useState({});

    useEffect(() => {
        fetchCryptoConfigurations();
        fetchFiatConfigurations();
        setPriceLimit(JSON.parse(sessionStorage.getItem("buyLimitWithourKyc")));
        getPaymentMethods();
    }, []);

    useEffect(() => {
        fetchCryptoPrice();
        if (fiatValue > 0) {
            fetchExchangeRate(fiatValue, selectedFiat, selectedCrypto?.value);
        } else {
            setCryptoValue(0);
        }
    }, [selectedFiat, selectedCrypto]);

    useEffect(() => {
        fetchCryptoPrice();
        if (fiatValue > 0) {
            fetchExchangeRate(fiatValue, selectedFiat, selectedCrypto?.value);
        } else {
            setCryptoValue(0);
        }
    }, [selectedPaymentCode?.value]);

    useEffect(() => {
        getPaymentMethods();
    }, [selectedFiat])

    const getPaymentMethods = () => {
        const auth = btoa(`transfi_demo:some_password`);
        setLoading(true);
        const options = {
            method: 'GET',
            headers: {
                "Authorization": `Basic ${auth}`,
                "Content-Type": "application/json"
            }
        };

        fetch(`https://sandbox-api.transfi.com/buy/paymentMethods?currency=${selectedFiat}`, options)
            .then(response => response.json())
            .then(resp => {
                const response = resp?.paymentMethods || [];
                const paymentOptions = response.map((payment) => {
                    return { label: payment.name, value: payment.paymentCode };
                })
                setPaymentMethodList(paymentOptions || []);
                setSelectedPaymentCode(paymentOptions?.[0] || {})
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    const fetchCryptoConfigurations = () => {
        setLoading(true);
        fetch(`https://sandbox-microservices.transfi.com/api/exchanges/common/configuration?name=crypto&module=buyCrypto`)
            .then(response => response.json())
            .then(resp => {
                const { data } = resp || {};
                const cryptos = [];
                data?.docs?.map(cr => {
                    cryptos.push({
                        value: cr?.formattedSymbol,
                        label: `${cr?.symbol}${cr?.showNetwork ? " " + cr?.network : ""}`
                    })
                })
                setCryptoList(cryptos);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const fetchFiatConfigurations = () => {
        setLoading(true);
        fetch(`https://sandbox-microservices.transfi.com/api/exchanges/common/configuration?name=fiat&module=buyCrypto`)
            .then(response => response.json())
            .then(resp => {
                const { data } = resp || {};
                let fiats = [];
                data?.docs?.map(cr => {
                    fiats.push({ value: cr?.description, label: cr?.description, countryCode: cr?.countryCode2Digit })
                })
                fiats = unionBy(fiats, "value")
                setFiatList(fiats);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const fetchCryptoPrice = () => {
        setLoading(true);
        fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${cryptosList[selectedCrypto?.value]}&vs_currencies=${selectedFiat},usd`)
            .then(response => response.json())
            .then(data => {
                setCryptoDetails(data);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const onClickBuy = () => {
        if (selectedCustomer === "Openpayd") {
            if (fiatValue <= 0 || cryptoValue <= 0) {
                setError("Fill some amount to buy.")
            } else {
                // openModal(true);
                createOrder();
            }
        }
    }

    const createOrder = () => {
        setLoading(true);
        const auth = btoa(`openpayd:XsLXL8PsQDFpBllS`);
        let countryTicker = fiatList.find(cc => cc.value === selectedFiat)
       if(isArray(countryTicker?.countryCode)){
        countryTicker = countryTicker?.countryCode[0]
       }
       else{
        countryTicker = countryTicker?.countryCode
       }

        const body = {
            "firstName": "John",
            "lastName": "Doe",
            "email": "johndoe@email.com",
            "country": countryTicker,
            "amount": fiatValue,
            "currency": selectedFiat,
            "cryptoCurrency": cryptoCurrency,
            "paymentType": "bank_transfer",
            "purposeCode": "expense_or_medical_reimbursement",
            "redirectUrl": "https://demo.transfi.com/order-summary",
            "type": "individual",
            "partnerContext": {
                "testfield1": "1234",
                "testfield2": "5678"
            }
        }

        const options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Authorization": `Basic ${auth}`,
                "Content-Type": "application/json"
            }
        };

        fetch(`https://sandbox-api.transfi.com/v2/orders/deposit`, options)
            .then(response => response.json())
            .then(resp => {
                console.log(resp)
                setPaymentUrl(resp?.paymentUrl);
                console.log(resp)
                setOrderDetails({ ...body, orderId: resp?.orderId, paymentMethod: selectedPaymentCode?.label });
                sessionStorage.setItem('orderDetails', JSON.stringify({ ...body, orderId: resp?.orderId, paymentMethod: selectedPaymentCode?.label }));
                setIsOpen(true);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    const onChangeFiat = (e) => {
        if (error) {
            setError(false)
        }
        setFiatValue(e.target.value);
        debouncedFetchData(e.target.value, selectedFiat, selectedCrypto?.value);
    }

    const customerMap = ['Openpayd', 'A Pay', 'AB Ramp', 'C Money']
    const fetchExchangeRate = (amount, fiat, cryptoTicker) => {
        setError(false);
        setLoadingExchangeRates(true);
        setCryptoCurrency(cryptoTicker);
        let fiatCurrency = fiat || selectedFiat;
        const limitsMin = priceLimit['min'] || JSON.parse(sessionStorage.getItem("buyLimitWithourKyc"))['min'];
        const limitsMax = priceLimit['max'] || JSON.parse(sessionStorage.getItem("buyLimitWithourKyc"))['max'];
        if (amount < limitsMin[fiatCurrency]) {
            setError(`Min Amount Should be ${(limitsMin[fiatCurrency])} ${fiatCurrency}`);
            setLoadingExchangeRates(false);
            return;
        } else if (amount > limitsMax[fiatCurrency]) {
            setError(`Max Amount Should be ${limitsMax[fiatCurrency]} ${fiatCurrency}`);
            setLoadingExchangeRates(false);
            return;
        }
        let quoteVersion = ['SGD', 'VND', 'MYR', 'THB', "JPY", "PHP", "IDR"].includes(fiatCurrency) ? "v2" : "v1";

        let paymentType = quoteVersion === "v2" ? "local" : "card";
        if (!isEmpty(selectedPaymentCode) && selectedPaymentCode?.value.includes("card")) {
            paymentType = "card";
        } else if (!isEmpty(selectedPaymentCode) && selectedPaymentCode?.value.includes("sepa_bank") && selectedPaymentCode?.value.includes("sepa_bank_va")) {
            paymentType = "sepa_bank";
        } else if (!isEmpty(selectedPaymentCode) && selectedPaymentCode?.value.includes("bank")) {
            paymentType = "bank";
        }


        setSelectedPaymentType(paymentType);
        fetch(`https://sandbox-microservices.transfi.com/api/exchanges/common/exchangeRates?cryptoTicker=${cryptoTicker}&fiatTicker=${fiatCurrency}&amount=${amount}&product=buy&paymentType=${paymentType}&quoteVersion=${quoteVersion}&apiKey=XsLXL8PsQDFpBllS`)
            .then(response => response.json())
            .then(resp => {
                const quotes = { ...resp?.data, customer: "openpayd" };
                let quotesList = [];
                quotesList.push({ ...quotes, receiveAmount: (quotes?.receiveAmount), receiveFiatAmount: (quotes?.receiveFiatAmount), customer: customerMap[0] });
                for (let i = 1; i < 4; i++) {
                    quotesList.push({ ...quotes, receiveAmount: (quotes?.receiveAmount / (1 + (i + 1) / 100)), receiveFiatAmount: (quotes?.receiveFiatAmount / (1 + (i + 1) / 100)), customer: customerMap[i] })
                }
                setQuotes(quotesList);
                setExchangeRateData(resp?.data);
                const { receiveAmount } = resp?.data || {};
                if (receiveAmount > 0) {
                    setCryptoValue(receiveAmount);
                }
                setLoadingExchangeRates(false);
            })
            .catch(error => {
                console.log(error);
                setError(error?.error?.message)
                setLoadingExchangeRates(false);
            });
    }

    const debouncedFetchData = useCallback(
        debounce((amount, selectedFiat, cryptoTicker) => fetchExchangeRate(amount, selectedFiat, cryptoTicker), 500),
        []
    );

    return (
        <>
            <>
                <div className='mb-8'>
                    <div className='text-center border-gray-300 p-1 border-[1px] mx-12 mt-8 rounded-md'>
                        {/* <input value="1" /> */}
                        <div class="flex">
                            <div class="relative w-full">
                                <input value={fiatValue} onChange={(e) => onChangeFiat(e)} type="number" class="block p-2.5 w-full z-20 text-sm text-gray-90 border-0 outline-none focus:ring-0 focus:ring-offset-0" placeholder="0.00" required />
                            </div>
                            <Dropdown
                                value={selectedFiat}
                                valueList={fiatList}
                                setSelected={setSelectedFiat}
                            />
                        </div>
                    </div>
                    <div className='text-left border-gray-300 p-1 mx-[45px] mt-2 rounded-md'>
                        <Select
                            isSearchable={true}
                            placeholder={"Cryptocurrency"}
                            value={selectedCrypto}
                            options={cryptoList}
                            onChange={(value) => {
                                setSelectedCrypto(value);
                            }}
                        />
                    </div>
                    {/* <div className='text-left border-gray-300 p-1 mx-[45px] mt-2 rounded-md'>
                        <Select
                            isSearchable={false}
                            placeholder={"Payment method"}
                            value={selectedPaymentCode}
                            options={paymentMethodList}
                            onChange={(value) => {
                                setSelectedPaymentCode(value);
                            }}
                        />
                    </div> */}
                </div>
                {error ? <div className='text-center my-3 mx-12'><p className='text-red-500'>{error}</p></div> : <></>}

                <div className='mb-6'>
                    {isEmpty(quotes) ? <div className='ml-[50%]'><Loadder /></div> : quotes.map(val => {
                        return (
                            <><div className='border-gray-300 py-4 px-6 border-[1px] mx-12 mt-2 rounded-md' key={val?.id} onClick={() => setSelectedCustomer(val?.customer)}>
                                <div className='flex justify-between'>
                                    <div className='text-left font-semibold mt-2'>
                                        <p>{val?.customer}</p>
                                    </div>
                                    <div className='text-right ml-auto'>
                                        <b>{round(val?.receiveAmount, 4)} {selectedCrypto?.value}</b><br />
                                        <p className='text-sm'>≈{round(val?.receiveFiatAmount, 2)} {selectedFiat}</p>
                                    </div>
                                </div>
                                {(selectedCustomer === val?.customer) && <button onClick={() => { onClickBuy() }} type="button" class="w-full text-white bg-[#1a4fd6] hover:bg-[#1a4fd6ad] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-4 focus:outline-none cursor-pointer">{`Continue with ${val?.customer}`}</button>}
                            </div>
                            </>
                        );
                    })}
                </div>
                {/* <div className='text-center my-3 mx-12'>
                    {loading || loadingExchangeRates ? <div style={{ marginLeft: '48%' }}><Loadder /></div> : <button disabled={error ? true : false} onClick={() => { onClickBuy() }} type="button" class="w-full text-white bg-[#1a4fd6] hover:bg-[#1a4fd6ad] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none cursor-pointer">{`Buy ${selectedCrypto}`}</button>}
                </div> */}
            </>
            <TransfiModal isOpen={isOpen} setIsOpen={setIsOpen} iframeUrl={paymentUrl} selecetedPaymentType={selectedPaymentType} />
        </>
    )
}
