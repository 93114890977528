import React, { useState, useEffect } from 'react';
import './ProgressComponent.css';  // Assuming you have the CSS defined in this file
import { startCase } from 'lodash';

const ProgressComponent = () => {
    const [status, setStatus] = useState("in_progress");

    useEffect(() => {
        const interval = setInterval(() => {
            setStatus("success")
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    const statusMap = {
        in_progress: "bg-yellow-100",
        "success": "bg-teal-100"
    }
    const Badge = () => {
        const newClass = `inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium text-black ${statusMap[status || "in_progress"]}`;
        return <span className={newClass}>{startCase(status)}</span>
    }
    return (
        <div class="flex flex-col w-[500px] mx-auto mt-16">
            <div class="-m-1.5 overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="border rounded-lg shadow overflow-hidden dark:border-neutral-700 dark:shadow-gray-900">
                        <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                            <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">Order Id</td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">OR-12344555666767</td>

                                </tr>

                                <tr>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">Status</td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200"><Badge /></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressComponent;
