import logo from '../../transfi-logo.svg'

import { useNavigate } from "react-router-dom";

const Sell = () => {
    const navigate = useNavigate();

    return <>
        <div class="welcome">
            <div class="welcome2">
                <h3 class="welcome3">WELCOME TO PRODUCT DEMO - SELL</h3>
            </div>
        </div>
        <div class="flex w-screen items-center justify-center mt-16">
            <div class="w-[33%] cursor-pointer" onClick={() => navigate("/sell/stand-alone")}>
                <div class="text-center max-w-sm rounded overflow-hidden mx-auto drop-shadow-2xl  h-[300px] bg-white border-b-4 border-white-500 hover:border-blue-600 transition duration-500 ease-in-out">
                    <img class="w-1/4 mx-auto mt-16" src={logo} alt="Image" />
                    <div class="px-6 py-4">
                        <div class="font-thin text-3xl mb-2  text-center text-[#0F2288]">Full Integration</div>
                    </div>
                </div>
            </div>
            <div class="w-[33%] px-4 cursor-pointer" onClick={() => navigate("/demo-with-kyc?type=sell")}>
                <div class="text-center max-w-sm rounded overflow-hidden mx-auto drop-shadow-2xl  h-[300px] bg-white border-b-4 border-white-500 hover:border-blue-600 transition duration-500 ease-in-out">
                    <img class="w-1/4 mx-auto mt-16" src={logo} alt="Image" />
                    <div class="px-6 py-4">
                        <div class="font-thin text-3xl mb-2 text-center text-[#0F2288]">Customized Integration</div>
                    </div>
                </div>
            </div>
            <div class="w-[33%] pr-12 cursor-pointer" onClick={() => navigate("/demo-without-kyc?type=sell")}>
                <div class="text-center max-w-sm rounded overflow-hidden mx-auto drop-shadow-2xl  h-[300px] bg-white border-b-4 border-white-500 hover:border-blue-600 transition duration-500 ease-in-out">
                    <img class="w-1/4 mx-auto mt-16" src={logo} alt="Image" />
                    <div class="px-6 py-4">
                        <div class="font-thin text-3xl mb-2 text-center text-[#0F2288]">Fast Checkout</div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Sell;